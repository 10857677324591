import React from 'react';
import {createRoot} from 'react-dom/client';
import HeaderBadge from './HeaderBadge.jsx';
import JshModule from '@core/scripts/helper/jsh-module';

const {moduleScope, config} = JshModule('header/header-badge');

createRoot(moduleScope).render(
    <HeaderBadge headerCart={config.headerCart} url={config.url} messageCode={config.messageCode} />
);


